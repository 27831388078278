<template>
  <VSelectWithValidation
    v-model="innerValue"
    label="Gender"
    name="gender"
    :items="genders"
    text="text"
    value="value"
    menu-props="offset-y"
    placeholder="Select Gender"
    v-bind="$attrs"
  />
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

import { genders } from '@/config'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'

export default defineComponent({
  name: 'SelectGender',
  components: {
    VSelectWithValidation,
  },
  props: {
    value: {
      type: null,
      default: null,
    },
  },
  setup(props, { emit }) {
    const innerValue = ref('')

    if (props.value) {
      innerValue.value = props.value
    }

    // Handles internal model changes.
    watch(innerValue, newVal => emit('input', newVal))

    // Handles external model changes.
    watch(_ => props.value, newVal => innerValue.value = newVal)

    return {
      innerValue,
      genders,
    }
  },
})
</script>
