<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :title="$t('Edit Handle')" :prev-route="prevRoute" :loading="pending">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <SearchSelectClient
                      v-model="clients_id"
                      rules="required"
                      :client="handle.client.contact_handle"
                      persistent-placeholder
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SelectGender v-model="sex" rules="required" />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="organisation"
                      label="Organisation"
                      name="organisation"
                      placeholder="Enter Organisation"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="firstname"
                      rules="required"
                      label="Firstname"
                      name="firstname"
                      placeholder="Enter Firstname"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="lastname"
                      rules="required"
                      label="Lastname"
                      name="lastname"
                      placeholder="Enter Lastname"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="email"
                      rules="required"
                      label="E-Mail Address"
                      name="email"
                      placeholder="Enter E-Mail Address"
                    />
                  </v-col>

                  <v-col cols="12" sm="8">
                    <VTextFieldWithValidation
                      v-model="street"
                      rules="required"
                      label="Street"
                      name="street"
                      placeholder="Enter Street"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="number"
                      rules="required"
                      label="Number"
                      name="number"
                      placeholder="Enter Number"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="zip"
                      rules="required"
                      label="Zip"
                      name="zip"
                      placeholder="Enter Zip"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="city"
                      rules="required"
                      label="City"
                      name="city"
                      placeholder="Enter City"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="country"
                      rules="required"
                      label="Country"
                      name="country"
                      placeholder="Enter Country"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="phone"
                      rules="required"
                      label="Phone"
                      name="phone"
                      placeholder="Enter Phone"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation v-model="fax" label="Fax" name="fax" placeholder="Enter Fax" />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="invalid || pendingSave"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import SearchSelectClient from '@/components/Clients/SearchSelectClient'
import SelectGender from '@/components/UI/SelectGender'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'EditHandle',
  components: {
    AppCard,
    ItemWrapper,
    SearchSelectClient,
    SelectGender,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Handles')

    // store
    const handle = computed(_ => $store.state.handle.handle)
    const pending = computed(_ => $store.state.handle.pending)
    const pendingSave = computed(_ => $store.state.handle.pendingSave)
    const errors = computed(_ => $store.state.handle.errors)
    const editHandle = _ => $store.dispatch('handle/editHandle')
    const getEditHandle = _ => $store.dispatch('handle/getEditHandle')
    const clearHandle = _ => $store.dispatch('handle/clearHandle')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    getEditHandle()

    const submitForm = _ => {
      if (pendingSave.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editHandle().then(({ handle, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Bearbeitung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Handle erfolgreich bearbeitet',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'handles-item',
            params: { id: handle.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearHandle()
    })

    return {
      handle,
      pendingSave,
      pending,
      errors,
      prevRoute,
      submitForm,
      ...mapFields('handle', [
        'handle.clients_id',
        'handle.sex',
        'handle.firstname',
        'handle.lastname',
        'handle.organisation',
        'handle.street',
        'handle.number',
        'handle.zip',
        'handle.country',
        'handle.city',
        'handle.phone',
        'handle.fax',
        'handle.email',
      ]),
    }
  },
})
</script>

